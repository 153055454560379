












































































































































import { Component, Vue } from 'vue-property-decorator';
import Icon from '../components/Common/Icon.vue';
import Paypal from '../components/Public/Paypal.vue';

@Component({
  components: { Icon, Paypal },
})
export default class Home extends Vue {}
